import { Link } from 'react-router-dom';
import './styles.css';

const Events = () => {
  return (
    <div className="content-events">
      <div className="container p-0 container-events">
        <Link to={`${process.env.PUBLIC_URL}/event-page`}>
          <div
            className="section-background"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + '/images/new/bg-cafe.jpg'
              })`,
            }}
          >
            <div className="container section-container">
              <div className="section-content">
                <div className="logo-container">
                  <img
                    src={process.env.PUBLIC_URL + '/images/new/logo-sic.png'}
                    alt="Logo SIC"
                    className="logo-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export { Events };
