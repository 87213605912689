import React from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
import Breadcrumb from 'components/common/Breadcrumb';
import DashbordWrap from './components/DashboardWrap';

const Dashboard = () => {
  return (
    <>
      <Breadcrumb pageName="Dashboard" pageTitle="Dashboard" />
      <DashbordWrap />
      <AboutUsCounter />
    </>
  );
};

export { Dashboard };
