import React from 'react';
import Breadcrumb from 'components/common/Breadcrumb';
import FaqWrap from './components/FaqWrap';

const Faq = () => {
  return (
    <>
      <Breadcrumb pageName="FAQ" pageTitle="FAQ" />
      <FaqWrap />
    </>
  );
};

export { Faq };
