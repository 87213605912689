import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './styles.css';

const ModalFooter = ({
  onClick,
  onClose,
  disabled,
  isLoading,
  textButton,
  typeButton = 'button',
}) => {
  return (
    <div className="component-modal-footer">
      <button className="component-buttonCancel" onClick={onClose}>
        Cancelar
      </button>
      <button
        type={typeButton}
        onClick={onClick}
        disabled={isLoading || disabled}
        className="component-buttonSave"
      >
        {isLoading && <CircularProgress size={18} color="#FFF" />}
        {textButton}
      </button>
    </div>
  );
};

export { ModalFooter };
