import React, { useState } from 'react';
import { authApi } from 'api';
import { FormInput } from 'components/form-elements/form-input';
import { intl } from 'lib/intl';
import { Notification } from 'components/common/Notification';
import { saveSession } from 'utils/handleSession';
import { Typography } from '@mui/material';

const BuyerRegistrationForm = ({
  onClose,
  callback,
  hasAccount,
  setHasAccount,
}) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState({
    message: '',
    type: '',
  });

  const resetNotificationMessage = () =>
    setNotificationMessage({ message: '', type: '' });

  const validateEmail = (email) => {
    // Validação básica de email usando regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (formEvent) => {
    formEvent.preventDefault();
    try {
      setLoading(true);
      const form = new FormData(formEvent.target);
      const data = {
        name: form.get('name'),
        email: form.get('email'),
        phone: form.get('phone'),
        country: form.get('country') || 'Brasil',
        city: form.get('city'),
      };

      if (!validateEmail(data.email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Email inválido',
        }));
        setLoading(false);
        return;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, email: null }));
      }

      await authApi.signUp({ ...data, password: '123' });

      const session = await authApi.createSession({
        email: data.email,
        password: '123',
      });
      saveSession(session);

      formEvent.target.reset();
      setNotificationMessage({
        message: 'Comprador criado com sucesso.',
        type: 'success',
      });
      if (callback) callback(session);
      onClose();
    } catch (err) {
      setNotificationMessage({
        message: err?.response?.data?.error,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Notification
        message={notificationMessage?.message}
        isOpen={!!notificationMessage?.message}
        onClose={resetNotificationMessage}
        type={notificationMessage?.type}
      />
      <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
        <Typography variant="h4" component="h2" style={{ fontWeight: 'bold' }}>
          {intl('singUpTitle')}
        </Typography>
        <Typography variant="body1">
          {intl('alreadyMember')}
          {` `}
          <span
            onClick={() => setHasAccount(true)}
            style={{ fontWeight: 'bold', cursor: 'pointer', color: '#3b5534' }}
          >
            {intl('logIn')}
          </span>
        </Typography>
      </div>
      <form onSubmit={handleSubmit} className="w-100 d-flex flex-column gap-3">
        <FormInput name="name" label={intl('name')} required />
        <FormInput
          name="email"
          label={intl('email')}
          required
          error={errors.email}
        />
        <FormInput
          name="phone"
          label={intl('phone')}
          placeholder="ex: 55 11 91234-5678"
          required
        />
        <FormInput
          name="country"
          label={intl('country')}
          defaultValue="Brasil"
          required
        />
        <FormInput name="city" label={intl('city')} required />
        <button
          type="submit"
          disabled={loading}
          className="button-registration-form"
        >
          {intl('singUpAction')}
        </button>
      </form>
    </>
  );
};

export { BuyerRegistrationForm };
