import React from 'react';
import ErrorWrapper from './components/ErrorWrapper';

const ErrorPage = () => {
  return (
    <>
      {/* <Breadcrumb pageName="404" pageTitle="Error Page" /> */}
      <ErrorWrapper />
    </>
  );
};

export { ErrorPage };
