import React, { useCallback, useState, useEffect } from 'react';
import { intl } from 'lib/intl';
import { adminApi, bidsApi } from 'api';
import { toPrice } from 'utils/converters';
import { Pagination } from 'components/pagination';
import { useHistory } from 'react-router-dom';
import { SkeletonLoading } from 'components/common/SkeletonLoading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const PAGE_SIZE = 10;

const FILTERS = {
  page: 1,
  pageSize: PAGE_SIZE,
};

function OffersReceived() {
  const history = useHistory();

  const [offersReceived, setOffersReceived] = useState({});
  const [filters, setFilters] = useState(FILTERS);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (filters) => {
    try {
      setLoading(true);
      const bids = await adminApi.listAdminOffers(filters);
      console.info(bids);
      setOffersReceived(bids);
    } catch (err) {
      console.error('Error fetching buyer data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => getData(filters), [getData, filters]);

  const handlePage = (_, page) => {
    setFilters((old) => ({ ...old, page, pageSize: PAGE_SIZE }));
  };

  const changePerPage = (e) => {
    setFilters((old) => ({ ...old, pageSize: e.target?.value || PAGE_SIZE }));
  };

  const goToBatch = (id, eventId) => {
    const linkTo = `${process.env.PUBLIC_URL}/batch-details/${id}/${eventId}`;
    history.push(linkTo);
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="v-pills-order"
        role="tabpanel"
        aria-labelledby="v-pills-order-tab"
      >
        <div className="table-title-area">
          <h3>Ofertas Recebidas</h3>
        </div>
        <div className="table-wrapper">
          {loading ? (
            <SkeletonLoading.TableLoading />
          ) : (
            <table className="eg-table order-table table mb-0">
              <thead>
                <tr>
                  <th>Imagem</th>
                  <th>Lote</th>
                  <th>Valor</th>
                  <th>Produtor</th>
                  <th>Comprador</th>
                  <th>Data</th>
                  <th>Cidade</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {offersReceived?.content?.length > 0 &&
                  offersReceived?.content?.map((item) => {
                    const bgImage =
                      process.env.PUBLIC_URL + '/images/bg/empty-image.png';

                    const firstPhotoUrl = item.photos?.[0]?.imageUrl || bgImage;

                    return (
                      <tr key={item.id}>
                        <td className="image-cell" data-label="Imagem">
                          <img
                            alt={item.name}
                            src={firstPhotoUrl}
                            className="img-fluid"
                          />
                        </td>
                        <td data-label={'Lote'}>{item.batch_name}</td>
                        <td data-label={'Valor'}>{toPrice(item?.value)}</td>
                        <td data-label={'Produtor'}>{item?.producer_name}</td>
                        <td data-label={'Comprador'}>{item.user_name}</td>
                        <td data-label={'Data'}>
                          {dayjs(item.created_at)
                            .tz('America/Sao_Paulo')
                            .format('DD/MM/YYYY HH:mm:ss')}
                        </td>

                        <td data-label={'Cidade'} className="text-green">
                          {item?.producer_city} / {item?.producer_state}
                        </td>
                        <td data-label="">
                          <button
                            onClick={() => goToBatch(item?.batch_id, ' ')}
                            className="eg-btn action-btn green"
                          >
                            <VisibilityIcon style={{ color: '#fff' }} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        <Pagination
          page={filters.page}
          rowsPerPage={filters.pageSize}
          onChange={handlePage}
          changePerPage={changePerPage}
          total={offersReceived?.pagination?.total}
        />
      </div>
    </>
  );
}

export { OffersReceived };
