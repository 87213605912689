import React, { useEffect, useState } from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
import DashbordWrap from './components/DashboardWrap';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { getSession } from 'utils/handleSession';

const MyAccount = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const session = getSession();
    if (!session) {
      history.push(`${process.env.PUBLIC_URL}/buyer-registration`);
    } else {
      setLoading(false);
    }
  }, [history]);

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <DashbordWrap />
      <AboutUsCounter />
    </>
  );
};

export { MyAccount };
