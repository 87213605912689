import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import 'swiper/css/autoplay';

SwiperCore.use([Pagination, Autoplay, EffectFade]);

function HeroBanner() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Function to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bennarSlider = {
    slidesPerView: 1,
    speed: 250,
    loop: true,
    spaceBetween: 10,
    centeredSlides: true,
    roundLengths: true,
    parallax: true,
    effect: 'fade',
    navigation: false,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.hero-one-pagination',
      clickable: true,
    },
  };

  return (
    <div className="hero-area hero-style-one">
      <div className="hero-main-wrapper position-relative">
        <Swiper {...bennarSlider} className="swiper banner1">
          <div className="swiper-wrapper">
            <SwiperSlide className="swiper-slide">
              <div style={{ height: 'auto', width: '100%' }}>
                <img
                  style={{ width: '100%' }}
                  src={
                    isMobile
                      ? process.env.PUBLIC_URL +
                        '/images/bg/banner-mobile-01.png'
                      : process.env.PUBLIC_URL + '/images/bg/banner-01.png'
                  }
                  alt="Banner 1"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div style={{ height: 'auto', width: '100%' }}>
                <img
                  style={{ width: '100%' }}
                  src={
                    isMobile
                      ? process.env.PUBLIC_URL +
                        '/images/bg/banner-mobile-02.png'
                      : process.env.PUBLIC_URL + '/images/bg/banner-02.png'
                  }
                  alt="Banner 2"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div style={{ height: 'auto', width: '100%' }}>
                <img
                  style={{ width: '100%' }}
                  src={
                    isMobile
                      ? process.env.PUBLIC_URL +
                        '/images/bg/banner-mobile-03.png'
                      : process.env.PUBLIC_URL + '/images/bg/banner-03.png'
                  }
                  alt="Banner 3"
                />
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
        <div className="hero-one-pagination d-flex justify-content-center flex-column align-items-center gap-3" />
      </div>
    </div>
  );
}

export default HeroBanner;
