// BuyerLoginForm.js
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { FormInput } from 'components/form-elements/form-input';
import { PasswordInput } from 'components/form-elements/form-password-input';
import { Link } from 'react-router-dom';
import { intl } from 'lib/intl';
import { authApi } from 'api';
import { saveSession } from 'utils/handleSession';

import { Typography } from '@mui/material';
import { Notification } from 'components/common/Notification';

const BuyerLoginForm = ({ onClose, callback, setHasAccount }) => {
  const [errors] = useState({});
  const [loading, setLoading] = useState(false);

  const [notificationMessage, setNotificationMessage] = useState({
    message: '',
    type: '',
  });

  const resetNotificationMessage = () =>
    setNotificationMessage({ message: '', type: '' });

  const handleLoginSubmit = async (formEvent) => {
    formEvent.preventDefault();
    try {
      setLoading(true);
      const form = new FormData(formEvent.target);
      const email = form.get('email');
      const password = form.get('password');

      const session = await authApi.createSession({ email, password });
      saveSession(session);

      onClose();
      if (callback) callback(session);
    } catch (err) {
      setNotificationMessage({
        message: err.response.data.error,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Notification
        message={notificationMessage?.message}
        isOpen={!!notificationMessage?.message}
        type={notificationMessage?.type}
        onClose={resetNotificationMessage}
      />
      <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
        <Typography variant="h4" component="h2" style={{ fontWeight: 'bold' }}>
          {intl('logInAction')}
        </Typography>
        <Typography variant="body1">
          {intl('newMember')}{' '}
          <span
            style={{ fontWeight: 'bold', cursor: 'pointer', color: '#3b5534' }}
            onClick={() => setHasAccount(false)}
          >
            {intl('signUp')}
          </span>
        </Typography>
      </div>
      <form
        onSubmit={handleLoginSubmit}
        className="w-100 d-flex flex-column gap-3"
      >
        <FormInput
          name="email"
          label={intl('email')}
          placeholder="ex: joaoenzo@gmail.com"
          error={errors?.email}
          required
        />
        <PasswordInput name="password" label={intl('password')} required />
        <div
          className="form-group d-flex align-items-center"
          style={{ gap: '8px' }}
        >
          <input type="checkbox" id="terms" required />
          <label htmlFor="terms">
            {intl('agreeTerms')}{' '}
            <Link to={'#'}>
              {intl('terms')} & {intl('policy')}
            </Link>
          </label>
        </div>
        <button className="button-registration-form" disabled={loading}>
          {intl('logInAction')}
        </button>
      </form>
    </>
  );
};

export { BuyerLoginForm };
