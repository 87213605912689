import React from 'react';
import Breadcrumb from 'components/common/Breadcrumb';
import BlogDetailsWrap from './components/BlogDetailsWrap';
import AboutUsCounter from 'components/common/AboutUsCounter';

const BlogDetails = () => {
  return (
    <>
      <Breadcrumb pageName="Blog Details" pageTitle="Blog Details" />
      <BlogDetailsWrap />
      <AboutUsCounter />
    </>
  );
};

export { BlogDetails };
