import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAdmin } from 'utils/handleAuth';

function AdminRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin() ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/login`} />
        )
      }
    />
  );
}

export { AdminRoute };
