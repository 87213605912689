import React, { useEffect } from 'react';
import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import Clarity from '@microsoft/clarity';
import TagManager from 'react-gtm-module'

function Layout({ children }) {
  useEffect(() => {
    const projectId = "ozz745u5ts"
    const tagManagerArgs = {
      gtmId: 'G-3QZYYTYJTB'
    }
    TagManager.initialize(tagManagerArgs)
    Clarity.init(projectId);
  }, [])
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
