function Label({ width, height }) {
  return (
    <div className="placeholder-glow" style={{ minWidth: '2.5vw' }}>
      <span class="placeholder w-75 d-block" style={{ width, height }}></span>
    </div>
  );
}

function Card() {
  return (
    <p class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-7"></span>
      <span class="placeholder col-7"></span>
      <span class="placeholder col-7"></span>
      <span class="placeholder col-7"></span>
      <span class="placeholder col-7"></span>
    </p>
  );
}

function BatchCard() {
  return (
    <div class="card vh-50" aria-hidden="true">
      <div class="card-body">
        <h5 class="card-title placeholder-glow">
          <span class="placeholder col-6"></span>
        </h5>
        <p class="card-text placeholder-glow">
          <span class="placeholder col-7"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-6"></span>
          <span class="placeholder col-8"></span>
        </p>
      </div>
    </div>
  );
}

function TableLoading() {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">
              <span className="placeholder col-6"></span>
            </th>
            <th scope="col">
              <span className="placeholder col-6"></span>
            </th>
            <th scope="col">
              <span className="placeholder col-6"></span>
            </th>
            <th scope="col">
              <span className="placeholder col-6"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
            <td>
              <span className="placeholder col-6"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export const SkeletonLoading = {
  Label,
  Card,
  BatchCard,
  TableLoading,
};
