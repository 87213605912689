import React from 'react';
import { IntlProvider } from 'react-intl';
import enMessages from 'locales/en.json';
import ptMessages from 'locales/pt-br.json';

const messages = {
  en: enMessages,
  'pt-BR': ptMessages,
};

export const intl = (key) => {
  const locale = localStorage.getItem('locale') || 'en';
  return messages[locale][key] || key;
};

const IntlWrapper = ({ children }) => {
  const locale = localStorage.getItem('locale') || 'en';
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

export { IntlWrapper };
