const sessionKey = '@agroger:session';
const lastBatchKey = '@agroger:last_batch';

export function getSession() {
  const session = localStorage.getItem(sessionKey);
  return session ? JSON.parse(session) : null;
}

export function saveSession(session) {
  localStorage.setItem(sessionKey, JSON.stringify(session));
}

export function getLastBatch() {
  const link = sessionStorage.getItem(lastBatchKey);
  return link ? link : null;
}

export function clearSession() {
  localStorage.removeItem(sessionKey);
}

export function saveLastBatch(link) {
  sessionStorage.setItem(lastBatchKey, link);
}
