import { apiBase } from '../index';

export const batchsApi = {
  create: async (data) =>
    await apiBase.post('/batches', data).then((res) => res.data),
  list: async (params) =>
    await apiBase.get('/batches', { params }).then((res) => res.data),
  randomList: async (params) =>
    await apiBase.get('/batches/random', { params }).then((res) => res.data),
  get: async (id) =>
    await apiBase.get(`/batches/${id}`).then((res) => res.data),
  update: async (id, newData) =>
    await apiBase.put(`/batches/${id}`, newData).then((res) => res.data),
  remove: async (id) =>
    await apiBase.delete(`/batches/${id}`).then((res) => res.data),
};
