import { useAuctionContext } from '../../';
import { intl } from 'lib/intl';
import { Rating } from '../rating';
import { SkeletonLoading } from 'components/common/SkeletonLoading';
import Tooltip from '@mui/material/Tooltip';

import './styles.css';

export function BatchTitle({ batchId }) {
  const { batch, producer, loading } = useAuctionContext();

  if (loading) return <HeaderLoading />;

  return (
    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
      <div className="about-coffee-container">
        <div className="producer-info">
          <h3 className="m-0">{producer?.propertyName}</h3>
          <p className="para m-0 fs-5">{producer?.name}</p>
        </div>
        <Rating batchId={batchId} />
      </div>
      <div className="contentBatchTitle">
        <div className="contentTitle">
          <div className="scoreBox">
            <img
              className="img-fluid"
              style={{ width: '20px', height: '20px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/medalha.svg'}
            />
            <span className="textScore">{batch?.score}</span>
          </div>
          <div className="preparationBox">
            <Tooltip title={batch?.preparation || ''}>
              <span className="textPreparation">{batch?.preparation}</span>
            </Tooltip>
          </div>
          <div className="speciesBox">
            <img
              className="img-fluid"
              style={{ width: '20px', height: '20px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/seed.svg'}
            />
            <span className="textSpecies">{batch?.species}</span>
          </div>
        </div>

        {producer?.altitudeMax ? (
          <div className="rowBatchTitle">
            <img
              className="img-fluid"
              style={{ width: '20px', height: '20px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/map.svg'}
            />
            <span className="text-dark fw-semibold">
              {producer?.altitudeMax} {intl('meters')}
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

function HeaderLoading() {
  return (
    <>
      <div className="mb-2">
        <div className="d-flex flex-column gap-3">
          <SkeletonLoading.Label width={60} height={50} />
          <SkeletonLoading.Label height={30} />
        </div>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <div className="bg-light px-3 py-1 rounded d-flex align-items-center gap-1">
          <img
            className="img-fluid"
            style={{ width: '20px', height: '20px' }}
            alt="liveAuctionCardImage"
            src={process.env.PUBLIC_URL + '/images/icons/medalha.svg'}
          />
          <span className="text-success fw-semibold">
            <SkeletonLoading.Label width={5} height={5} />
          </span>
        </div>
        <div className="bg-light px-3 py-1 rounded">
          <span className="text-success fw-semibold">
            <SkeletonLoading.Label width={5} height={5} />
          </span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <img
            className="img-fluid"
            style={{ width: '20px', height: '20px' }}
            alt="liveAuctionCardImage"
            src={process.env.PUBLIC_URL + '/images/icons/seed.svg'}
          />
          <span className="text-dark fw-semibold specie">
            <SkeletonLoading.Label width={5} height={5} />
          </span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <img
            className="img-fluid"
            style={{ width: '20px', height: '20px' }}
            alt="liveAuctionCardImage"
            src={process.env.PUBLIC_URL + '/images/icons/map.svg'}
          />
          <span className="text-dark fw-semibold">
            <SkeletonLoading.Label width={20} height={5} />
          </span>
        </div>
      </div>
    </>
  );
}
