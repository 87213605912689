import React from 'react';
import { LanguageSelector } from 'components/language-selector';

function TopbarHeader() {
  return (
    <div className="topbar">
      <div className="topbar-left d-flex flex-row align-items-center">
        <h6> </h6>
        <ul className="topbar-social-list gap-2">
          <li>
            <a href="https://www.facebook.com/">
              {/* <i className="bx bxl-facebook" /> */}
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com/">
              {/* <i className="bx bxl-twitter" /> */}
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/">
              {/* <i className="bx bxl-instagram" /> */}
            </a>
          </li>
          <li>
            <a href="https://www.pinterest.com/">
              {/* <i className="bx bxl-pinterest-alt" /> */}
            </a>
          </li>
        </ul>
      </div>
      <div className="email-area">
        <h6>
          <a href="mailto:contact@example.com">contato@selectacafe.com.br</a>
        </h6>
      </div>
      <div className="topbar-right">
        <LanguageSelector />
      </div>
    </div>
  );
}
export default TopbarHeader;
