import Input from 'react-input-mask';
import { maskTypes } from './components/mask-types';
import { TextField } from '../components';

export const InputMaskForm = ({
  mask,
  label,
  value,
  onChange,
  placeholder,
  disabled = false,
  required = false,
  ...props
}) => {
  const handleOnChange = (event) => {
    const newValue = event.target.value;
    if (onChange) onChange(newValue);
  };

  const maskType = maskTypes[mask];

  return (
    <Input
      mask={maskType}
      maskPlaceholder=""
      value={value}
      required={required}
      disabled={disabled}
      onChange={handleOnChange}
      children={() => {
        return (
          <div className="w-full">
            <TextField
              fullWidth
              type="text"
              label={label}
              variant="outlined"
              placeholder={placeholder || label}
              style={{
                width: '100%',
                backgroundColor: 'white',
                opacity: disabled ? '0.4' : '1',
              }}
              {...props}
            />
          </div>
        );
      }}
    />
  );
};
