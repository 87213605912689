const getErrorMessageYup = (errors, name) => {
  if (!errors?.[name]) return '';
  const message = String(errors[name]?.message) || '';
  return message;
};

const toPrice = (val = null, minimum = null, maximum = null) => {
  const min = minimum || minimum === 0 ? minimum : 2;

  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: min,
    maximumFractionDigits: maximum || minimum || 2,
  }).format(Number(val));
};

const errorMessage = (error) => {
  return error?.response?.data?.error || '-';
};

const convertToNumber = (value) => {
  let result = value.replace('R$', '').trim();
  result = result.replace(/\./g, '');
  result = result.replace(',', '.');

  return parseFloat(result);
};

export { toPrice, errorMessage, getErrorMessageYup, convertToNumber };
