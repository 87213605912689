import { apiBase } from '../index';

export const producersApi = {
  create: async (data) =>
    await apiBase.post('/producers', data).then((res) => res.data),
  list: async (params) =>
    await apiBase.get('/producers', { params }).then((res) => res.data),
  get: async (id) =>
    await apiBase.get(`/producers/${id}`).then((res) => res.data),
  update: async (id, newData) =>
    await apiBase.put(`/producers/${id}`, newData).then((res) => res.data),
  remove: async (id) =>
    await apiBase.delete(`/producers/${id}`).then((res) => res.data),
};
