import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export function Notification({ message, isOpen, onClose, type }) {
  const [vertical, horizontal] = ['bottom', 'center'];

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      onClose={onClose}
      open={isOpen}
      key={vertical + horizontal}
      autoHideDuration={3000}
    >
      <Alert
        onClose={onClose}
        severity={type}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
