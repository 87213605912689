import { TextField } from '../components/text-field';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const FormSearch = ({
  label,
  value,
  onChange,
  placeholder,
  disabled = false,
  error = null,
  ...props
}) => {
  const handleOnChange = (event) => {
    const newValue = event.target.value;
    if (onChange) onChange(newValue);
  };

  return (
    <TextField
      fullWidth
      type="text"
      size="medium"
      value={value}
      label={label}
      variant="outlined"
      autoComplete="off"
      disabled={disabled}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={handleOnChange}
      placeholder={placeholder || label}
      error={error}
      style={{
        width: '100%',
        opacity: disabled ? '0.4' : '1',
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        },
      }}
      {...props}
    />
  );
};

export { FormSearch };
