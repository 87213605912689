import React from 'react';
import Breadcrumb from 'components/common/Breadcrumb';
import SignUpWrap from './components/SignUpWrap';

const SignUp = () => {
  return (
    <>
      <Breadcrumb pageName="Sign Up" pageTitle="Sign Up" />
      <SignUpWrap />
    </>
  );
};

export { SignUp };
