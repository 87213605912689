import { intl } from 'lib/intl';

export function TabOptions() {
  return (
    <ul
      className="nav nav-pills d-none d-lg-flex flex-row justify-content-start gap-sm-4 gap-3 wow fadeInDown"
      data-wow-duration="1.5s"
      data-wow-delay=".2s"
      id="pills-tab"
    >
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active details-tab-btn"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          {intl('description')}
        </button>
      </li>
      {/* <li className="nav-item" role="presentation">
        <button
          className="nav-link details-tab-btn"
          id="pills-bid-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-bid"
          type="button"
          role="tab"
          aria-controls="pills-bid"
          aria-selected="false"
        >
          Histórico de Licitações
        </button>
      </li> */}
      <li className="nav-item" role="presentation">
        <button
          className="nav-link details-tab-btn"
          id="pills-contact-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-contact"
          type="button"
          role="tab"
          aria-controls="pills-contact"
          aria-selected="false"
        >
          {intl('otherAuctions')}
        </button>
      </li>
    </ul>
  );
}
