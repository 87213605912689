import React, { useCallback, useState, useEffect } from 'react';
import { intl } from 'lib/intl';
import { adminApi, bidsApi } from 'api';
import { toPrice } from 'utils/converters';
import { Pagination } from 'components/pagination';
import { useHistory } from 'react-router-dom';
import { SkeletonLoading } from 'components/common/SkeletonLoading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Rating } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

const PAGE_SIZE = 10;

const FILTERS = {
  page: 1,
  pageSize: PAGE_SIZE,
};

function VotesReceived() {
  const history = useHistory();

  const [votesReceived, setVotesReceived] = useState({});
  const [filters, setFilters] = useState(FILTERS);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (filters) => {
    try {
      setLoading(true);
      const bids = await adminApi.listAdminVotes(filters);
      console.info(bids);
      setVotesReceived(bids);
    } catch (err) {
      console.error('Error fetching buyer data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => getData(filters), [getData, filters]);

  const handlePage = (_, page) => {
    setFilters((old) => ({ ...old, page, pageSize: PAGE_SIZE }));
  };

  const changePerPage = (e) => {
    setFilters((old) => ({ ...old, pageSize: e.target?.value || PAGE_SIZE }));
  };

  const goToBatch = (id, eventId) => {
    const linkTo = `${process.env.PUBLIC_URL}/batch-details/${id}/${eventId}`;
    history.push(linkTo);
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="v-pills-purchase"
        role="tabpanel"
        aria-labelledby="v-pills-purchase-tab"
      >
        <div className="table-title-area">
          <h3>Votos Recebidas</h3>
        </div>
        <div className="table-wrapper">
          {loading ? (
            <SkeletonLoading.TableLoading />
          ) : (
            <table className="eg-table order-table table mb-0">
              <thead>
                <tr>
                  <th>Nota</th>
                  <th>Comentário</th>
                  <th>Produtor</th>
                  <th>Avaliador</th>
                  <th>Data</th>
                  <th>Cidade</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {votesReceived?.content?.length > 0 &&
                  votesReceived?.content?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td data-label={'Nota'}>
                          <Rating
                            size="medium"
                            name="simple-controlled"
                            value={item.rating}
                            disabled={true}
                          />
                        </td>
                        <td data-label={'Comentário'}>{item.comment}</td>
                        <td data-label={'Produtor'}>{item?.producer_name}</td>
                        <td data-label={'Avaliador'}>{item.email}</td>
                        <td data-label={'Data'}>
                          {dayjs(item.created_at)
                            .tz('America/Sao_Paulo')
                            .format('DD/MM/YYYY HH:mm:ss')}
                        </td>

                        <td data-label={'Cidade'} className="text-green">
                          {item?.producer_city} / {item?.producer_state}
                        </td>
                        <td data-label="">
                          <button
                            onClick={() => goToBatch(item?.batch_id, ' ')}
                            className="eg-btn action-btn green"
                          >
                            <VisibilityIcon style={{ color: '#fff' }} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        <Pagination
          page={filters.page}
          rowsPerPage={filters.pageSize}
          onChange={handlePage}
          changePerPage={changePerPage}
          total={votesReceived?.pagination?.total}
        />
      </div>
    </>
  );
}

export { VotesReceived };
