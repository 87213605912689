import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import './styles.css';

const ModalHeader = ({ title, onClose }) => {
  return (
    <div className="component-modal-header">
      <span className="component-modal-title">{title}</span>
      <div className="component-modal-close-button">
        <IconButton onClick={onClose}>
          <CloseIcon size="small" className="component-icon-modal" />
        </IconButton>
      </div>
    </div>
  );
};

export { ModalHeader };
