import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import './styles.css';
SwiperCore.use([Navigation, Autoplay]);

const comments = [
  {
    id: 1,
    name: 'Thiago Douro',
    position: 'Jovem Cafeicultor',
    image: '/images/new/thiago.jpg',
    comment:
      'As expectativas de participação no cupping e rodada de negócios promovidos pela CNA são muito boas. É um ambiente onde serão conectados produtores e compradores, visando a valorização do café e da cadeia produtiva.',
  },
  {
    id: 2,
    name: 'Roque Lins',
    position: 'Cafeilcutor',
    image: '/images/new/roque.jpg',
    comment:
      `É a primeira vez que o estado do Amazonas participa do Cupping. Só temos que agradecer a grande oportunidade. Minha expectativa é muito grande, e espero fazer ótimos negócios. Vai ser show!
Vamos mostrar o que temos de melhor no café!`,
  },
  {
    id: 3,
    name: 'Carlos Meireles',
    position: 'Assessor Técnico da CNA',
    image: '/images/new/carlos.jpg',
    comment:
      `Espero que todos os nossos produtores participantes tenham a oportunidade de mostrar o melhor de suas produções, para compradores nacionais e internacionais. A expectativa é que grandes negócios se concretizem através do nosso projeto!!` ,
  },
  {
    id: 4,
    name: 'Ana Carolina Gomes',
    position: 'Analista de Agronegócios do Sistema Faemg Senar',
    image: '/images/new/carol.jpg',
    comment:
      `Há 3 anos nos desafiamos na realização da sala de cupping e negócios junto às ações do Sistema Faemg Senar é a cada ano que passa, cresce mais e mais. Faz parte de nosso trabalho criar essa importante conexão entre produtores e compradores. Fico muito feliz e realizada em proporcionar isso aos cafeicultores mineiros! 
`
},
  {
    id: 5,
    name: 'Maria Soraia',
    position: 'Produtora de Café',
    image: '/images/new/maria_soraia.jpg',
    comment:
      `Estou muito feliz em poder fazer parte desse encontro, com compradores nacionais e internacionais. Minhas expectativas são boas, pois terei essa grande oportunidade de apresentar meus cafés. Parabéns ao time da FAEMG, SENAR, CNA e a todos envolvidos nesse projeto. Obrigada pelo carinho e dedicação!`
  },
  {
    id: 6,
    name: 'Leandro Henrique',
    position: 'Cafeicultor de Natércia- MG',
    image: '/images/new/leandro.jpg',
    comment:
      `É uma grande alegria participar, pela primeira vez, de uma rodada de negócios tão importante, representando minha família, nossa associação e a força da agricultura familiar de nossa região. Estamos muito confiantes de que essa será uma oportunidade para dar visibilidade ao trabalho que realizamos com tanto amor pela terra, dedicação e respeito à natureza. Nosso café especial reflete tradição, união e um compromisso com a sustentabilidade ambiental e social. Esperamos surpreender com a qualidade do nosso produto, conquistar bons parceiros e abrir portas para um futuro próspero, levando o melhor do nosso café para o Brasil e o mundo.` 
  },
];

function TestimonialHome1() {
  const testimonialSlider = {
    slidesPerView: 1,
    speed: 500,
    spaceBetween: 24,
    loop: true,
    roundLengths: true,
    navigation: {
      nextEl: '.testi-prev1',
      prevEl: '.testi-next1',
    },

    breakpoints: {
      280: {
        slidesPerView: 1, // Mostrar "um card e meio"
        autoplay: true,
      },
      480: {
        slidesPerView: 1, // Mostrar "um card e meio"
        autoplay: true,
      },
      768: {
        slidesPerView: 1, // Mostrar "um card e meio"
        autoplay: true,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <div className="content-testimonial">
      <div className="container">
        <div className="row justify-content-center position-relative">
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              {comments.map((item) => (
                <SwiperSlide key={item?.id} className="swiper-slide">
                  <div
                    className="testimonial-single hover-border1 wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    <img
                      alt="testimonial-images"
                      src={
                        process.env.PUBLIC_URL + '/images/icons/quote-green.svg'
                      }
                      className="quote-icon"
                    />
                    <div className="testi-img">
                      <img
                        alt="testimonial-images"
                        src={process.env.PUBLIC_URL + item?.image}
                      />
                    </div>
                    <div className="testi-content">
                      <p className="para">{item?.comment}</p>
                      <div className="testi-designation">
                        <h5>
                          <Link to={'#'}>{item?.name}</Link>
                        </h5>
                        <p>{item?.position}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <div className="slider-arrows testimonial2-arrow d-flex justify-content-between gap-3">
            <div
              className="testi-prev1 swiper-prev-arrow"
              tabIndex={0}
              role="button"
              aria-label="Previous slide"
            >
              <i className="bi bi-arrow-left" />
            </div>
            <div
              className="testi-next1 swiper-next-arrow"
              tabIndex={0}
              role="button"
              aria-label="Next slide"
            >
              <i className="bi bi-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialHome1;
