// UserRegistrationModal.js
import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import { BuyerRegistrationForm } from '../buyer-registration-form';
import { BuyerLoginForm } from '../buyer-login-form';

const UserRegistrationModal = ({ open, onClose, callback }) => {
  const [hasAccount, setHasAccount] = useState(false);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="registration-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        {!hasAccount ? (
          <BuyerRegistrationForm
            onClose={onClose}
            callback={callback}
            hasAccount={hasAccount}
            setHasAccount={setHasAccount}
          />
        ) : (
          <BuyerLoginForm
            onClose={onClose}
            callback={callback}
            hasAccount={hasAccount}
            setHasAccount={setHasAccount}
          />
        )}
      </Box>
    </Modal>
  );
};

export { UserRegistrationModal };
