import React, { useState } from 'react';
import { intl } from 'lib/intl';
import { SkeletonLoading } from 'components/common/SkeletonLoading';
import { Chart } from 'components';
import { useAuctionContext } from '../../';
import { TabOptions } from './options';
import { OtherAuctions } from './other-auctions';
import { toPrice } from 'utils/converters';
import DescriptionIcon from '@mui/icons-material/Description';
import './styles.css';
import CircularProgress from '@mui/material/CircularProgress';

const series = [
  {
    name: 'Aroma',
    data: [6, 9, 6, 10, 8],
  },
  {
    name: 'Flavor',
    data: [10, 6, 8, 7, 10],
  },
  {
    name: 'Acidity',
    data: [7, 8, 7, 10, 9],
  },
  {
    name: 'Body',
    data: [8, 10, 9, 7, 8],
  },
  {
    name: 'After taste',
    data: [9, 6, 10, 7, 10],
  },
];

const URL_BASE = 'https://auction-artifacts.s3.us-east-1.amazonaws.com/sic';

export function AuctionDetailsTab() {
  const [isLoading, setIsLoading] = useState(false);
  const { batch, producer, loading } = useAuctionContext();

  const download = async (response, type) => {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${batch?.code}${type}`);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const checkFile = async () => {
      setIsLoading(true);
      console.log(batch)

      const getJpg = async () => {
        console.log('getJpg')
        try {
          const jpegResponse = await fetch(`/${URL_BASE}/laudos/${batch?.code}.jpeg`, {
            method: 'GET',
            headers: {
              Accept: 'image/jpeg',
            },
          });
          if (jpegResponse.ok) await download(jpegResponse, '.jpeg');
         
        } catch (err) {
          return false
        }
      }

      const getPdf = async () => {
        console.log('getPdf')
        try {
          const pdfResponse = await fetch(`${URL_BASE}/laudos/${batch?.code}.pdf`, {
            method: 'GET',
            headers: {
              Accept: 'application/pdf',
            },
          });          
          console.log(pdfResponse)
          if (pdfResponse.ok) await download(pdfResponse, '.pdf');
          return pdfResponse;
        } catch (err) {
          return getJpg()
        } finally {
          setIsLoading(false);
        }
      }

      await getPdf();
  };

  return (
    <div>
      <div className="w-100">
        <TabOptions />
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="content-info py-4 flex-column flex-lg-row">
              <div className="card-info shadow-standard">
                <h4 className="text-2xl">{intl('information')}</h4>
                {loading ? (
                  <SkeletonLoading.Card />
                ) : (
                  <div className="col-info">
                    <div className="row-info">
                      <span className="text-info-1">{intl('producer')}</span>
                      <span className="text-info-2">{producer?.name}</span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('farm')}</span>
                      <span className="text-info-2">
                        {producer?.propertyName}
                      </span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('location')}</span>
                      <span className="text-info-2">
                        {producer?.city || '-'}
                        {producer?.state && `/${producer?.state}`}
                      </span>
                    </div>

                    {producer?.website && (
                      <div className="row-info">
                        <span className="text-info-1">{intl('site')}</span>
                        <a
                          className="text-info-2"
                          href={producer?.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {producer?.website}
                        </a>
                      </div>
                    )}
                    <div className="row-info">
                      <span className="text-info-1">
                        {intl('coffeeDifferentiations')}
                      </span>
                      <span className="text-info-2">
                        {producer?.coffeeDifferentiation || '-'}
                      </span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">
                        {intl('certifications')}
                      </span>
                      <span className="text-info-2">
                        {producer?.certifications}
                      </span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">
                        {intl('geographicalIndication')}
                      </span>
                      <span className="text-info-2">
                        {producer?.geographicIndication}
                      </span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">
                        {intl('propertyArea')}
                      </span>
                      <span className="text-info-2">
                        {toPrice(producer?.propertyArea)}
                      </span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('differences')}</span>
                      <span className="text-info-2">
                        {batch?.shortDescription}
                      </span>
                    </div>
                  </div>
                )}
                <div className="w-full h-[1px] bg-gray-100" />
                <h4 className="text-2xl">{intl('last_lot_info')}</h4>
                {loading ? (
                  <SkeletonLoading.Card />
                ) : (
                  <div className="col-info">
                    <div className="row-info">
                      <span className="text-info-1">{intl('quantity')}</span>
                      <span className="text-info-2">
                        {batch?.quantityInSacks}
                      </span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('harvest')}</span>
                      <span className="text-info-2">{batch?.harvestYear}</span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('storage')}</span>
                      <span className="text-info-2">
                        {batch?.storageLocation}
                      </span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('variety')}</span>
                      <span className="text-info-2">{batch?.variety}</span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('screen')}</span>
                      <span className="text-info-2">{batch?.sieve}</span>
                    </div>
                    <div className="row-info">
                      <span className="text-info-1">{intl('preparation')}</span>
                      <span className="text-info-2">{batch?.preparation}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="card-info-2">
                <div className="w-full scoreContainer bg-white py-3 pe-3  rounded-[4px] flex flex-col shadow-standard">
                  <div>
                    <img
                      alt="medalha"
                      src={process.env.PUBLIC_URL + '/images/icons/medalha.png'}
                      className="img-fluid img-medalha"
                    />
                  </div>

                  <div className="">
                    <h1>{batch?.score}</h1>
                    {loading ? (
                      <SkeletonLoading.Label />
                    ) : (
                      <span className="text-5xl text-[#3B5534] font-bold">
                        {intl('tasting_note')}
                      </span>
                    )}
                  </div>
                </div>
                {batch?.fragrance && (
                  <div className="card-info">
                    <h4 className="text-2xl">{intl('evaluation')}</h4>
                    {loading ? (
                      <SkeletonLoading.Card />
                    ) : (
                      <div className="col-info">
                        <div className="row-info">
                          <span className="text-info-1">
                            {intl('tasting_note')}
                          </span>
                          <span className="text-info-2">{batch?.score}</span>
                        </div>
                        <div className="row-info">
                          <span className="text-info-1">
                            {intl('fragrance')}
                          </span>
                          <span className="text-info-2">
                            {batch?.fragrance}
                          </span>
                        </div>
                        <div className="row-info">
                          <span className="text-info-1">{intl('flavor')}</span>
                          <span className="text-info-2">{batch?.flavor}</span>
                        </div>
                        <div className="row-info">
                          <span className="text-info-1">{intl('finish')}</span>
                          <span className="text-info-2">
                            {batch?.aftertaste}
                          </span>
                        </div>
                        <div className="row-info">
                          <span className="text-info-1">{intl('acidity')}</span>
                          <span className="text-info-2">{batch?.acidity}</span>
                        </div>
                        <div className="row-info">
                          <span className="text-info-1">{intl('body')}</span>
                          <span className="text-info-2">{batch?.body}</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="card-info">
                  <button
                    onClick={checkFile}
                    disabled={isLoading}
                    className="button-download"
                  >
                    <div className="box-button-download">
                      {!isLoading && (
                        <DescriptionIcon sx={{ color: 'white' }} />
                      )}
                      {isLoading && (
                        <CircularProgress
                          style={{ color: 'white' }}
                          size={20}
                        />
                      )}
                    </div>
                    <div className="box-text-button-download">
                      <span className="title-button-download">
                        {intl('downloadDocumentation')}
                      </span>
                      <span className="subtitle-button-download ">
                        {intl('clickHere')}
                      </span>
                    </div>
                  </button>
                  {batch?.flavor !== '' && (
                    <>
                      <h4 className="text-2xl">{intl('sensory_evaluation')}</h4>
                      <div className="chart-radar">
                        <Chart.Radar series={series} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <OtherAuctions batch={batch} />
        </div>
      </div>
    </div>
  );
}
