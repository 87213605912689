import { useState, useEffect, useCallback } from 'react';
import './styles.css';
import { intl } from 'lib/intl';
import Slider from '@mui/material/Slider';
import { useParams } from 'react-router-dom';
import { batchsApi, producersApi } from 'api';
import { LiveAuctionCard } from 'components/live-auction-card';
import { Pagination, Loading, CardProducer, FormElements } from 'components';

const PAGE_SIZE = 20;

const FILTERS = {
  page: 1,
  pageSize: PAGE_SIZE,
};

const events = [
  {
    id: 'ategc',
    color: '#4cb95e',
    eventId: '3232f5b0-1216-45d4-9f55-9b873298291e',
    title: 'Concurso ATeG 2024 - Arábica',
  },
  {
    id: 'atega',
    color: '#0a6251',
    eventId: 'bdd004e1-598e-451c-8087-dec6ae338c5e',
    title: 'Concurso ATeG 2024 - Conilon',
  },
  {
    id: 'mcsf',
    color: '#ED433D',
    eventId: '970025bb-21ba-4f83-8608-1d6fe21b66b8',
    title: 'MCSF - Meu Café no Sistema Faemg',
  },
  {
    id: 'cna',
    color: '#f7ba00',
    eventId: '8244da77-cd5b-4edd-ae70-585842cb90f3',
    title: 'Cupping CNA',
  },
];

const EventDetails = () => {
  const { id } = useParams();
  const event = events.find((item) => item?.id === id);

  const [producers, setProducers] = useState(null);
  const [batches, setBatches] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(FILTERS);
  const [score, setScore] = useState([75, 100]);
  const [search, setSearch] = useState('');

  const getProducers = useCallback(async () => {
    try {
      const params = {
        page: 1,
        pageSize: 20,
        finalist: 1,
        // eventId: event?.eventId,
      };
      const [producers] = await Promise.all([producersApi.list(params)]);
      setProducers(producers.content);
    } catch (err) {}
  }, [event]);

  const getBatchs = useCallback(
    async (filters) => {
      try {
        setLoading(true);
        const params = {
          ...filters,
          eventId: event?.eventId,
        };
        const [batchs] = await Promise.all([batchsApi.list(params)]);
        setBatches(batchs);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [event],
  );

  useEffect(() => {
    getBatchs({ ...filters, search, minScore: score[0], maxScore: score[1] });
  }, [getBatchs, filters, search, score]);

  useEffect(() => {
    getProducers();
  }, [getProducers]);

  const handlePage = (_, page) => {
    setFilters((old) => ({ ...old, page, pageSize: PAGE_SIZE }));
  };

  const changePerPage = (e) => {
    setFilters((old) => ({ ...old, pageSize: e.target?.value || PAGE_SIZE }));
  };

  const handleChangeScore = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < 0) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - 0);
        setScore([clamped, clamped + 0]);
      } else {
        const clamped = Math.max(newValue[1], 0);
        setScore([clamped - 0, clamped]);
      }
    } else {
      setScore(newValue);
    }
  };

  return (
    <div>
      <div
        className="section-background-new"
        style={{ background: event?.color }}
      >
        <span className="title-event">{event?.title}</span>
      </div>
      <img
        alt="images"
        style={{
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
        src={process.env.PUBLIC_URL + '/images/new/banner-ateg.png'}
      />
      {producers?.length > 0 && (
        <div className="finalists-section">
          <div className="container">
            <h2 className="finalists-title mb-4">{intl('finalBidders')}</h2>
            <div className="card-grid">
              {producers?.map((item) => (
                <CardProducer
                  key={item.id}
                  producerId={item?.id}
                  length={producers?.length}
                  meters={item.altitudeMax}
                  producer={item.name}
                  variety={item.variety}
                  farm={item.propertyName}
                  image={item?.photo?.imageUrl}
                  city={item.city}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="lots-section">
        <div className="container">
          <h2 className="lots-title mb-4">{intl('lotsOnOffer')}</h2>
          <div className="filters-box">
            <FormElements.Search
              label={intl('search')}
              value={search}
              onChange={setSearch}
              placeholder={intl('searchProducerCityUfAltitude')}
            />
            <div className="filters-slider">
              <div className="filters-row ">
                <span className="filters-labels">{intl('minValue')}</span>
                <span className="filters-labels">{intl('maxValue')}</span>
              </div>
              <Slider
                min={75}
                max={100}
                sx={{ color: '#356b53' }}
                getAriaLabel={() => 'Minimum distance shift'}
                value={score}
                onChange={handleChangeScore}
                valueLabelDisplay="auto"
                disableSwap
              />
            </div>
          </div>
          {batches?.content?.length > 0 && (
            <div className="card-grid-a">
              {!loading &&
                batches?.content?.map((batch) => (
                  <LiveAuctionCard
                    eventId={event.eventId}
                    key={batch?.id}
                    id={batch?.id}
                    name={batch?.name}
                    photos={batch?.photos}
                    preparation={batch?.preparation}
                    variety={batch?.variety}
                    species={batch?.species}
                    score={batch?.score}
                    producer={batch?.producer}
                    favoriteId={batch?.favoriteId}
                    shortDescription={batch?.shortDescription}
                  />
                ))}
            </div>
          )}
          {loading && <Loading />}
          <div className="mt-4">
            <Pagination
              page={filters.page}
              rowsPerPage={filters.pageSize}
              onChange={handlePage}
              changePerPage={changePerPage}
              total={batches?.pagination?.total}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { EventDetails };
