import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from '@emotion/styled';

export const StyledDatePicker = styled(DatePicker)((props) => {
  return {
    ...props,
    '& > *': {
      borderColor: '#356B53 !important',
    },
    '& .MuiOutlinedInput-root': {
      color: '#3A3938',
      '& fieldset': {
        color: '#495057',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#356B53',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#495057',
      '&.Mui-focused': {
        color: '#356B53',
      },
      '& .MuiFormHelperText-root': {
        color: '#3A3938',
      },
    },
    '& .MuiInputBase-input': {
      padding: '5px 10px',
      height: '45px',
    },
  };
});
