import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TextField from '@mui/material/TextField';
import { StyledDatePicker } from '../components/styled-date-picker';

dayjs.extend(customParseFormat);

export function DatePickerInput({ defaultValue, onChange, error, ...props }) {
  // Converte a data padrão para dayjs
  const parsedValue = defaultValue ? dayjs(defaultValue, 'YYYY-MM-DD') : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <StyledDatePicker
        {...props}
        value={parsedValue}
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue ? newValue.format('YYYY-MM-DD') : null);
          }
        }}
        inputFormat="DD/MM/YYYY"
        style={{ height: '57px !important' }}
        renderInput={(params) => (
          <TextField
            style={{ padding: '0px !important' }}
            {...params}
            error={!!error}
            helperText={error || ''}
            fullWidth={false} // Impede que o input ocupe o espaço total
            sx={{ maxWidth: '200px' }} // Define uma largura máxima para o input
          />
        )}
      />
    </LocalizationProvider>
  );
}
