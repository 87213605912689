import React from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
import Breadcrumb from 'components/common/Breadcrumb';
import WhyCHooseUs from 'pages/how-it-work/components/WhyCHooseUs';
import AboutTestimonial from './components/AboutTestimonial';
import WhoWeAreArea from './components/WhoWeAreArea';

const About = () => {
  return (
    <>
      <Breadcrumb pageName="About Us" pageTitle="About Us" />
      <WhoWeAreArea />
      <WhyCHooseUs />
      <AboutTestimonial />
      <AboutUsCounter />
    </>
  );
};

export { About };
