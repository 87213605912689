import React, { useState, useEffect } from 'react';
import './styles.css';

const emptyImage = process.env.PUBLIC_URL + '/images/bg/empty-image.png';

const ImagesSliderAuction = ({ photos = [] }) => {
  const [mainImage, setMainImage] = useState({});
  const moreThanOneImage = photos?.length > 1;

  useEffect(() => {
    if (photos?.length > 0 && !mainImage?.id) {
      setMainImage(photos[0]);
    }
  }, [mainImage, photos]);

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  return (
    <div className="container-isa">
      {photos?.length > 0 && (
        <>
          {moreThanOneImage && (
            <div className="content-box-imagens-isa">
              {photos
                .filter((item) => item.id !== mainImage.id)
                .map((item) => (
                  <button
                    key={item.id}
                    className="button-isa"
                    onClick={() => handleImageClick(item)}
                  >
                    <img
                      alt="image-auction"
                      src={item?.imageUrl}
                      className="image-isa"
                    />
                  </button>
                ))}
            </div>
          )}
          <div
            className="box-a-isa"
            style={{
              width: moreThanOneImage ? '70% !important' : '100% !important',
            }}
          >
            <img
              alt="image-auction"
              src={mainImage?.imageUrl}
              className="image-isa"
            />
          </div>
        </>
      )}
      {photos?.length === 0 && (
        <div className="box-a-isa-empty-state w-100">
          <img alt="image-auction" src={emptyImage} className="image-isa" />
        </div>
      )}
    </div>
  );
};

export { ImagesSliderAuction };
