import { TextField as TextFieldMUI, styled } from '@mui/material';

export const TextField = styled(TextFieldMUI)(({ error }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#495057',
    '&:before': {
      borderBottomColor: '#356B53',
    },
  },
  '& .MuiFilledInput-root:after': {
    borderBottomColor: '#356B53',
  },
  '& .MuiOutlinedInput-root': {
    color: '#3A3938',
    '& fieldset': {
      color: '#495057',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#356B53',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#495057',
    '&.Mui-focused': {
      color: '#356B53',
    },
    '& .MuiFormHelperText-root': {
      color: '#3A3938',
    },
  },
  '& .MuiInputBase-input': {
    padding: '5px 10px',
    height: '45px',
  },
  ...(error && {
    '& .MuiFilledInput-root': {
      backgroundColor: '#495057',
      '&:before': {
        borderBottomWidth: '2px',
        borderBottomColor: '#F46A6A',
      },
    },
    '& .MuiFilledInput-root:after': {
      borderBottomColor: '#F46A6A',
    },
    '& .MuiOutlinedInput-root': {
      color: '#3A3938',
      '& fieldset': {
        borderColor: '#F46A6A !important',
        borderWidth: '2px',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#F46A6A',
      '&.Mui-focused': {
        color: '#F46A6A',
      },
    },
    '& .MuiFormHelperText-root': {
      color: '#F46A6A',
    },
  }),
}));
