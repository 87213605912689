import './styles.css';
import { intl } from 'lib/intl';
import { Link } from 'react-router-dom';

const EventPage = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div>
      <div
        className="section-background"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + '/images/new/bg-cafe.jpg'
          })`,
        }}
      >
        <div className="container section-container">
          <div className="section-content">
            <div className="logo-container">
              <img
                src={process.env.PUBLIC_URL + '/images/new/logo-sic.png'}
                alt="Logo SIC"
                className="logo-image"
              />
            </div>
            <div className="text-box">
              <p className="text-sic-a">{intl('sicEventApresentation')}</p>
              <p className="text-sic">{intl('sicEventOffer')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content-evets">
          <Link
            to={`${process.env.PUBLIC_URL}/event-details/ategc`}
            onClick={scrollTop}
            className="event-ATeG-c"
          >
            Concurso ATeG 2024 Arábica
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/event-details/atega`}
            onClick={scrollTop}
            className="event-ATeG-a"
          >
            Concurso ATeG 2024 Conilon
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/event-details/mcsf`}
            onClick={scrollTop}
            className="event-MCSF"
          >
            MCSF - Meu Café no Sistema Faemg
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/event-details/cna`}
            onClick={scrollTop}
            className="event-CNA"
          >
            Cupping CNA
          </Link>
        </div>
      </div>
    </div>
  );
};

export { EventPage };
