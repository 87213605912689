import React from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
import Breadcrumb from 'components/common/Breadcrumb';
import ContactWrapper from './components/ContactWrapper';

const Contact = () => {
  return (
    <>
      <Breadcrumb pageName="Contact Us" pageTitle="Contact Us " />
      <ContactWrapper />
      <AboutUsCounter />
    </>
  );
};

export { Contact };
