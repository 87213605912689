import React, { useState } from 'react';
import { MenuItem, Select, FormControl } from '@mui/material';

const LanguageSelector = () => {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en');

  const handleLanguageChange = (event) => {
    const selectedLocale = event.target.value;
    localStorage.setItem('locale', selectedLocale);
    setLocale(selectedLocale);
    window.location.reload();
  };

  return (
    <FormControl variant="outlined">
      <Select
        value={locale}
        size="small"
        style={{ width: '200px' }}
        variant="outlined"
        onChange={handleLanguageChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="pt-BR">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={process.env.PUBLIC_URL + '/images/icons/brazil.svg'}
              alt="Brasil"
              style={{ width: 20, marginRight: 8 }}
            />
            <span>Português</span>
          </div>
        </MenuItem>
        <MenuItem value="en">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={process.env.PUBLIC_URL + '/images/icons/eua.svg'}
              alt="EUA"
              style={{ width: 20, marginRight: 8 }}
            />
            <span>English</span>
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export { LanguageSelector };
