import React from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
import Breadcrumb from 'components/common/Breadcrumb';
import HowItWorkContent from './components/HowItWorkContent';
import WhyCHooseUs from './components/WhyCHooseUs';

const HowItWork = () => {
  return (
    <>
      <Breadcrumb pageName="How It Works" pageTitle="How It Works" />
      <HowItWorkContent />
      <WhyCHooseUs />
      <AboutUsCounter />
    </>
  );
};

export { HowItWork };
