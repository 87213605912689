import { apiBase } from '../index';

export const ratingApi = {
  batchVote: async (data) =>
    await apiBase.post('/batch-votes', data).then((res) => res.data),
  getRate: async (batchId, params) =>
    await apiBase
      .get(`/batch-votes/${batchId}`, { params })
      .then((res) => res.data)
      .catch((err) => err.message),
};
