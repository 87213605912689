import { apiBase } from '../index';

export const favoritesApi = {
  create: async (data) =>
    await apiBase.post('/favorites', data).then((res) => res.data),
  get: async (id) =>
    await apiBase.get(`/favorites/${id}`).then((res) => res.data),
  remove: async (id) =>
    await apiBase.delete(`/favorites/${id}`).then((res) => res.data),
};
