import React from 'react';
import Chart from 'react-apexcharts';

const colorList = [
  '#007377',
  '#356B53',
  '#FF8A00',
  '#C49AFF',
  '#FF9500',
  '#007377',
  '#356B53',
  '#FF8A00',
  '#C49AFF',
  '#FF9500',
  '#007377',
  '#356B53',
  '#FF8A00',
  '#C49AFF',
  '#FF9500',
];

const ChartRadar = ({ series = [], height = 200 }) => {
  const categories = series.map((item) => item.name);
  const colorEnabled = colorList.slice(0, series.length);

  const options = {
    chart: {
      type: 'radar',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              width: '100%',
              height: '300px',
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
              height: '250px',
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    xaxis: {
      categories,
    },
    markers: {
      size: 0,
    },
    fill: {
      opacity: 0.25,
      colors: colorEnabled,
    },
    stroke: {
      width: 2,
      colors: colorEnabled,
    },
    plotOptions: {
      radar: {
        size: 80,
      },
    },
    legend: {
      show: true,
      horizontalAlign: 'center',
      position: 'right',
      floating: false,
      offsetY: 0,
      fontSize: '14px',
      labels: {
        colors: ['#333'],
      },
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
  };

  return (
    <Chart options={options} series={series} type="radar" height={height} />
  );
};

export { ChartRadar };
