import React from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
import Breadcrumb from 'components/common/Breadcrumb';
import MerchantWrap from './components/MerchantWrap';

const Merchant = () => {
  return (
    <>
      <Breadcrumb pageName="Join Merchant" pageTitle="Join Merchant" />
      <MerchantWrap />
      <AboutUsCounter />
    </>
  );
};

export { Merchant };
