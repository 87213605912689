import React from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
import DashbordWrap from './components/DashboardWrap';

const AdminDashboard = () => {
  return (
    <>
      <DashbordWrap />
      <AboutUsCounter />
    </>
  );
};

export { AdminDashboard };
