import { useEffect, useState } from 'react';
import { producersApi } from 'api';
import { useParams, Link } from 'react-router-dom';
import { LiveAuctionCard } from 'components/live-auction-card';

import './styles.css';

const ProducerDetails = () => {
  const [producer, setProducer] = useState(null);

  const { id } = useParams();

  async function getData() {
    try {
      // const producer = await producersApi.get(
      //   'b3f08d62-9c46-4d5a-b0f4-09f14e8999e7',
      // );
      const producer = await producersApi.get(id);
      console.info(producer);
      setProducer(producer);
    } catch (err) {
      throw new Error(err);
    }
  }

  useEffect(() => getData(), []);

  const bgImage = process.env.PUBLIC_URL + '/images/bg/empty-image.png';

  const img = producer?.photo?.imageUrl || bgImage;

  return (
    <div className="master-container">
      <div className="container new-back-container">
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Link
            to={`${process.env.PUBLIC_URL}/event-page`}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="btn btn-success fw-semibold"
            style={{ backgroundColor: '#356B53' }}
          >
            Voltar
          </Link>
        </div>
      </div>
      <div className="container new-section-container">
        <div className="shadow-standard new-card-producer">
          <div className="new-producer-details">
            <div className="new-bidder-img">
              <img alt="images" className="new-producer-image" src={img} />
            </div>
            <div className="new-text-producer">
              <h4 className="new-producer-name">{producer?.name}</h4>
              <span className="new-farm-name">{producer?.propertyName}</span>
              <div className="new-info-icons">
                <div className="new-info-icon-item">
                  <img
                    className="img-fluid new-info-icon"
                    alt="coffeeTypeIcon"
                    src={process.env.PUBLIC_URL + '/images/icons/seed.svg'}
                  />
                  <span className="text-dark fw-semibold">
                    {producer?.batches[0]?.variety}
                  </span>
                </div>
                <div className="new-info-icon-item">
                  <img
                    className="img-fluid new-info-icon"
                    alt="altitudeIcon"
                    src={process.env.PUBLIC_URL + '/images/icons/map.svg'}
                  />
                  <span className="text-dark fw-semibold">
                    {producer?.altitudeMax} Metros
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="new-location-info">
            <img
              className="img-fluid new-info-icon"
              alt="localIcon"
              src={process.env.PUBLIC_URL + '/images/icons/local.svg'}
            />
            <span className="text-dark fw-semibold">{producer?.location}</span>
          </div>
          <p className="m-0">{producer?.history}</p>
        </div>
      </div>
      <div className="container new-galeria-container">
        <h2 className="lots-title mb-8">Galeria</h2>
        <div className="card-galeria shadow-standard">
          <div className="card-galeria-scroll">
            <img
              alt="coffeeTypeIcon"
              style={{ width: '1000px', height: 'auto' }}
              src={process.env.PUBLIC_URL + '/images/new/galeria.png'}
            />
          </div>
        </div>
      </div>
      <div className="container new-lots-section">
        <h2 className="lots-title mb-8">Lotes disponíveis</h2>
        <div className="card-grid-a">
          {producer?.batches?.map((item) => (
            <LiveAuctionCard
              key={item.id}
              batchId={item?.id}
              meters={producer?.altitudeMax}
              producer={producer?.name}
              typeOfCoffee={item.variety}
              classification={item.classification}
              preparation={item.preparation}
              title={item.name}
              image={item.image}
              favoriteId={item?.favoriteId}
              photos={item?.photos}
              shortDescription={item.shortDescription}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export { ProducerDetails };
