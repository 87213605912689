import React, { useState, useCallback, useEffect, useRef } from 'react';

import { usersApi } from 'api';
import { intl } from 'lib/intl';
import { Notification } from 'components/common/Notification';

import { FormInput } from 'components/form-elements/form-input';
import { PasswordInput } from 'components/form-elements/form-password-input';
import { InputMaskForm } from 'components/form-elements/form-input-mask';
import { DatePickerInput } from 'components/form-elements/date-picker-input';
import { useAuctionContext } from 'pages/auction-details';
import { validarCPF } from 'utils/validarCpf';
import { validarEmail } from 'utils/validarEmail';
import dayjs from 'dayjs';

const formFields = [
  'name',
  'email',
  'cpf',
  'rg',
  'password',
  'birthDate',
  'country',
  'state',
  'city',
  'postalCode',
  'streetAddress',
];

function ContentOfProfile() {
  const formRef = useRef(null); // Referência ao formulário
  const [notificationMessage, setNotificationMessage] = useState({
    message: '',
    type: '',
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [buyer, setBuyer] = useState({
    name: '',
    streetAddress: '',
    postalCode: '',
    country: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    cpf: '',
    rg: '',
  });

  function resetNotificationMessage() {
    setNotificationMessage({
      message: '',
      type: '',
    });
  }

  const hasErrors = Object.keys(errors).length > 0;

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const buyerData = await usersApi.get();
      setBuyer(buyerData);
    } catch (err) {
      console.error('Error fetching buyer data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  async function handleSubmit(formEvent) {
    const form = new FormData(formEvent.target);
    const data = formFields.reduce((obj, field) => {
      obj[field] = form.get(field);
      return obj;
    }, {});

    formEvent.preventDefault();
    try {
      setLoading(true);

      await usersApi.update(data);
      setNotificationMessage({
        message: intl('notificationUpdateUser'),
        type: 'success',
      });
      getData();
    } catch (err) {
      setNotificationMessage({
        message: err?.response?.data?.error,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  // function handleCPFValidation(value) {
  //   console.log('value', value);
  //   const isValid = validarCPF(value);
  //   setErrors((errors) => ({
  //     ...errors,
  //     cpf: isValid ? null : 'CPF inválido',
  //   }));
  // }

  function handleEmailValidation(value) {
    if (value) {
      const isValid = validarEmail(value);
      setErrors((errors) => ({
        ...errors,
        email: isValid ? null : 'Email inválido',
      }));
    }
  }

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (formRef.current) {
      Object.entries(buyer).forEach(([key, value]) => {
        if (formRef?.current[key]) {
          formRef.current[key].value = value || '';
        }
      });
    }
  }, [buyer]);

  return (
    <>
      <Notification
        message={intl(notificationMessage?.message)}
        isOpen={!!notificationMessage?.message}
        type={notificationMessage?.type}
        onClose={resetNotificationMessage}
      />
      <div
        className="tab-pane fade"
        id="v-pills-profile"
        role="tabpanel"
        aria-labelledby="v-pills-profile-tab"
      >
        <div className="dashboard-profile">
          <div className="owner">
            <div className="content">
              <h3>{buyer.name}</h3>
              <p className="para">{buyer.email}</p>
            </div>
          </div>
          <div className="form-wrapper">
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="w-100 d-flex flex-column gap-3"
            >
              <FormInput
                name="name"
                label={intl('name')}
                placeholder="ex: João Enzo"
                error={errors?.name}
                required
              />
              <FormInput
                name="email"
                label={intl('email')}
                placeholder="ex: joaoenzo@gmail.com"
                error={errors?.email}
                required
                onChange={(e) => handleEmailValidation(e?.target?.value)}
              />
              <div className="d-flex flex-wrap flex-lg-nowrap gap-3">
                {/* <InputMaskForm
                name="cpf"
                label={'CPF'}
                mask="cpf"
                placeholder="ex: 111.111.111-11"
                error={errors?.cpf}
                required
                onChange={(e) => handleCPFValidation(e.target.value)}
              /> */}
                <FormInput
                  name="cpf"
                  label={intl('Cpf')}
                  placeholder="ex: 111.111.111-11"
                  error={errors?.name}
                  required
                />
                <FormInput name="rg" label={'RG'} error={errors?.rg} required />
              </div>
              <FormInput
                name="phone"
                label={intl('phone')}
                placeholder="ex: (00) 9 1567-1498"
                error={errors?.name}
                required
              />
              {/* <InputMaskForm
              name="phone"
              label={intl('phone')}
              mask="phone"
              placeholder="ex: (00) 9 1567-1498"
              defaultValue={buyer.phone}
              required
            /> */}

              <DatePickerInput
                name="birthDate"
                label={intl('birthdate')}
                error={errors?.birthDate}
                defaultValue={buyer?.birthDate}
              />
              <FormInput
                name="country"
                label={intl('country')}
                error={errors?.country}
                type="address"
                placeholder="ex: Brasil"
              />
              <FormInput
                name="state"
                label={intl('state')}
                error={errors?.state}
                type="address"
                placeholder="ex: MG"
              />
              <FormInput
                name="city"
                label={intl('city')}
                error={errors?.city}
                type="address"
                placeholder="ex: Governador Valadares"
              />
              <FormInput
                name="postalCode"
                label={intl('zipCode')}
                error={errors?.postalCode}
                type="address"
                placeholder="ex: 12345-678"
              />
              <FormInput
                name="streetAddress"
                label={intl('address')}
                error={errors?.streetAddress}
                type="address"
                placeholder={`ex: ${intl('streetExample')}`}
              />
              <button
                type="submit"
                className="account-btn"
                disabled={loading || hasErrors}
              >
                {intl('saveInfo')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentOfProfile;
