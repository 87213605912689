import React from 'react';
import Pagination from '@mui/material/Pagination';
const paginationContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Saira, sans-serif',
};

const selectStyle = {
  cursor: 'pointer',
  display: 'block',
  width: '100%',
  padding: '0.5rem 0.75rem',
  border: '1px solid #3b5534',
  backgroundColor: '#fff',
  borderRadius: '0.375rem',
  color: '#212529',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
  outline: 'none',
  fontFamily: 'Saira, sans-serif',
  fontSize: '0.875rem',
};

const PaginationComponent = ({
  page,
  onChange,
  rowsPerPage,
  changePerPage,
  rowsPerPagePersonality = [20, 30, 50],
  total,
  ...props
}) => {
  const count = Math.ceil(total / rowsPerPage);

  return (
    <div style={paginationContainerStyle}>
      <Pagination
        siblingCount={1}
        page={page}
        onChange={onChange}
        count={count}
        shape="rounded"
        sx={{
          '& .MuiPaginationItem-root': {
            background: 'transparent',
            color: '#212529',
            fontFamily: 'Saira, sans-serif',
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#3b5534',
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Saira, sans-serif',
          },
          '& .MuiPaginationItem-page:hover': {
            fontFamily: 'Saira, sans-serif',
            backgroundColor: 'rgba(10, 31, 45, 0.1)',
          },
        }}
        {...props}
      />
      <div style={{ cursor: 'pointer' }}>
        <select
          value={rowsPerPage}
          onChange={changePerPage}
          style={selectStyle}
        >
          {rowsPerPagePersonality.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export { PaginationComponent as Pagination };
