import { useState, useEffect, useCallback } from 'react';

import { intl } from 'lib/intl';
import { v4 as uuidv4 } from 'uuid';
import { ratingApi } from 'api/rating';
import Alert from '@mui/material/Alert';
import { errorMessage } from 'utils/converters';
import { Modal, FormElements } from 'components';
import { Rating as RatingMUI } from '@mui/material';

import './styles.css';

const getDeviceId = () => {
  let deviceId = localStorage.getItem('deviceId');

  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem('deviceId', deviceId);
  }

  return deviceId;
};

const getDeviceIdLocalStorage = () => {
  let deviceId = localStorage.getItem('deviceId');
  return deviceId;
};

const messageError =
  'Your device has already registered a vote for this batch. Each user may rate only once.';

const Rating = ({ batchId }) => {
  const deviceId = getDeviceIdLocalStorage();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isVoted, setIsVoted] = useState(false);
  const [isModalRating, setIsModalRating] = useState(false);
  const [alert, setAlert] = useState({
    severity: 'warning',
    description: 'É permitido votar uma vez por lote.',
  });
  const [isBatchVoteLoading, setIsBatchVoteLoading] = useState(false);

  const getRate = useCallback(async (batchId, deviceId) => {
    try {
      const response = await ratingApi.getRate(batchId, { deviceId });
      const isVoteValid = !!response?.rating;
      setRating(isVoteValid ? response.rating : 0);
      setIsVoted(isVoteValid);
    } catch (error) {
      setRating(0);
      setIsVoted(false);
    }
  }, []);

  useEffect(() => {
    if (deviceId && batchId) {
      getRate(batchId, deviceId);
    }
  }, [getRate, deviceId, batchId]);

  useEffect(() => {
    setComment('');
    setAlert({
      severity: 'warning',
      description: 'É permitido votar uma vez por lote.',
    });
  }, [isModalRating]);

  const batchVote = async () => {
    try {
      setIsBatchVoteLoading(true);
      const data = {
        batchId,
        deviceId: getDeviceId(),
        rating,
        comment,
      };
      await ratingApi.batchVote(data);
      setIsModalRating(false);
      setIsVoted(true);
    } catch (error) {
      setIsVoted(false);

      if (errorMessage(error) === messageError)
        setAlert({
          severity: 'error',
          description: intl('batchVoteMessage'),
        });
      throw new Error(error);
    } finally {
      setIsBatchVoteLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      await batchVote();
    } catch {}
  };

  return (
    <div className="contentRating">
      <div className="boxRating">
        <span className="textRating">
          {isVoted ? intl('isVoted') : intl('voteLot')}
        </span>
        <RatingMUI
          size="medium"
          name="simple-controlled"
          value={rating}
          disabled={isVoted}
          onChange={(event, newValue) => {
            setRating(newValue);
            setIsModalRating(true);
          }}
        />
        <Modal
          open={isModalRating}
          onClick={handleSubmit}
          title={intl('voteLot')}
          isLoading={isBatchVoteLoading}
          textButton={intl('submitVote')}
          onClose={() => setIsModalRating(false)}
        >
          <div className="contentModalRating">
            {alert?.severity && alert?.description && (
              <Alert severity={alert.severity} className="alertModal">
                {alert.description}
              </Alert>
            )}
            <div className="colModalRating">
              <span>{intl('rateReason')}</span>
              <RatingMUI
                size="large"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </div>
            <FormElements.Input
              multiline
              rows={4}
              value={comment}
              label="Comentário"
              onChange={setComment}
              placeholder={intl('writeCommentProducer')}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};
export { Rating };
