import React, { useCallback, useEffect, useState } from 'react';

import './styles.css';
import { batchsApi } from 'api';
import { useParams } from 'react-router-dom';
import { LiveAuctionCard } from 'components/live-auction-card';

export function OtherAuctions({ batch: batchData }) {
  const { eventId } = batchData || { eventId: '' };
  const [listRandomBatches, setListRandomBatches] = useState({});

  const getBatchsRandomList = useCallback(async () => {
    try {
      const response = await batchsApi.randomList({ eventId });
      console.info(response);
      setListRandomBatches(response);
    } catch (err) {}
  }, [eventId]);

  useEffect(() => {
    if(eventId) getBatchsRandomList();
  }, [getBatchsRandomList, eventId]);

  return (
    <div
      style={{
        marginTop: 20,
      }}
      className="tab-pane fade mobile-display"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div className="card-grid-a">
        {listRandomBatches?.content?.length > 0 &&
          listRandomBatches?.content?.map((batch) => (
            <LiveAuctionCard
              key={batch?.id}
              eventId={eventId}
              id={batch?.id}
              name={batch?.name}
              photos={batch?.photos}
              preparation={batch?.preparation}
              variety={batch?.variety}
              species={batch?.species}
              score={batch?.score}
              producer={batch?.producer}
              favoriteId={batch?.favoriteId}
              shortDescription={batch?.shortDescription}
            />
          ))}
      </div>
    </div>
  );
}
