import WomanIcon from '@mui/icons-material/Woman';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import './styles.css';

const tagConfig = {
  mulher: {
    icon: <WomanIcon className="tag-text-color" />,
    className: 'tag-woman',
  },
  ig: {
    icon: <SouthAmericaIcon className="tag-text-color" />,
    className: 'tag-ig',
  },
  certificado: {
    icon: <WorkspacePremiumIcon className="tag-text-color" />,
    className: 'tag-certificate',
  },
  jovem: {
    icon: <ChildCareIcon className="tag-text-color" />,
    className: 'tag-young',
  },
};

const Tags = ({ shortDescription = '' }) => {
  const tags = shortDescription
    .toLowerCase()
    .split(', ')
    .filter((word) => word in tagConfig);

  return (
    <div className="tags-container">
      {tags.map((tag) => (
        <div key={tag} className={tagConfig[tag].className}>
          {tagConfig[tag].icon}
        </div>
      ))}
    </div>
  );
};

export { Tags };
