import React, { useState, useCallback, useEffect } from 'react';
import { favoritesApi } from 'api';
import { intl } from 'lib/intl';
import './live-auction-card.css';
import { useAuth } from 'hooks/use-auth';
import { useHistory, Link } from 'react-router-dom';
import { Tags } from './tags';

const LiveAuctionCard = ({
  id,
  name,
  photos,
  variety,
  species,
  score,
  producer,
  favoriteId,
  eventId,
  preparation,
  shortDescription,
}) => {
  const { token } = useAuth();
  const history = useHistory();
  const [hasFavorite, setHasFavorite] = useState(false);

  const handleFavoriteClick = () => {
    if (!token) {
      history.push(`${process.env.PUBLIC_URL}/login`);
    } else {
      setHasFavorite(!hasFavorite);
      createFavorite();
    }
  };

  const createFavorite = useCallback(
    async (batchId) => {
      try {
        await favoritesApi.create({ batchId: id });
      } catch (err) {}
    },
    [id],
  );

  useEffect(() => {
    if (favoriteId) {
      setHasFavorite(true);
    }
  }, [favoriteId]);

  const bgImage = process.env.PUBLIC_URL + '/images/bg/empty-image.png';
  const linkTo = `${process.env.PUBLIC_URL}/batch-details/${id}`;
  const img = photos?.length > 0 ? photos[0]?.imageUrl : bgImage;

  return (
    <div className="auction-live-card">
      <div
        className="auction-card-image position-relative"
        style={{ backgroundImage: `url(${img})` }}
      >
        <button
          onClick={handleFavoriteClick}
          className="auction-favorite-button btn"
        >
          {hasFavorite && token ? (
            <img
              className="img-fluid auction-favorite-icon"
              alt="favorite"
              src={process.env.PUBLIC_URL + '/images/icons/heart-on.svg'}
            />
          ) : (
            <img
              className="img-fluid auction-favorite-icon"
              alt="not-favorite"
              src={process.env.PUBLIC_URL + '/images/icons/heart-off.svg'}
            />
          )}
        </button>
        <Tags shortDescription={shortDescription} />
      </div>
      <div className="auction-card-body">
        <div className="auction-classification-info">
          <div className="auction-classification-badge">
            <img
              className="img-fluid auction-classification-icon"
              alt="classificationIcon"
              src={process.env.PUBLIC_URL + '/images/icons/medalha.svg'}
            />
            <span className="auction-classification-text">{score}</span>
          </div>
          <div className="auction-classification-badge">
            <span className="auction-classification-text-preparation">
              {preparation}
            </span>
          </div>
        </div>
        <div className="auction-producer-info">
          <span className="auction-producer-title">{name}</span>
          <span className="auction-producer-subtitle">{producer?.name}</span>
        </div>
        <div className="d-flex gap-2 d-flex-mobile-column">
          <div className="auction-info-icon-item">
            <img
              className="img-fluid auction-info-icon"
              alt="coffeeIcon"
              src={process.env.PUBLIC_URL + '/images/icons/seed.svg'}
            />
            <span className="text-dark fw-semibold truncate">{variety}</span>
          </div>
          <div className="auction-info-icon-item">
            <img
              className="img-fluid auction-info-icon"
              alt="mapIcon"
              src={process.env.PUBLIC_URL + '/images/icons/map.svg'}
            />
            <span className="text-dark fw-semibold">
              {producer.altitudeMax
                ? `${producer.altitudeMax} ${intl('meters')}`
                : species}
            </span>
          </div>
        </div>
        <Link
          to={linkTo}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="btn btn-success w-100 fw-semibold"
          style={{ backgroundColor: '#356B53' }}
        >
          {intl('seeInformation')}
        </Link>
      </div>
    </div>
  );
};

export { LiveAuctionCard };
