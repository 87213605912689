import { authApi } from 'api';
import { FormInput } from 'components/form-elements/form-input';
import { PasswordInput } from 'components/form-elements/form-password-input';
import { InputMaskForm } from 'components/form-elements/form-input-mask';
import { DatePickerInput } from 'components/form-elements/date-picker-input';
import { intl } from 'lib/intl';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { validarCPF } from 'utils/validarCpf';
import { validarEmail } from 'utils/validarEmail';
import { useHistory } from 'react-router-dom';
import { saveSession, getLastBatch } from 'utils/handleSession';
import { Notification } from 'components/common/Notification';

const formFields = [
  'name',
  'email',
  'cpf',
  'rg',
  'password',
  'birthDate',
  'country',
  'state',
  'city',
  'postalCode',
  'streetAddress',
];

const BuyerRegistration = () => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const hasErrors = Object.keys(errors).length > 0;

  const [notificationMessage, setNotificationMessage] = useState({
    message: '',
    type: '',
  });

  function resetNotificationMessage() {
    setNotificationMessage({
      message: '',
      type: '',
    });
  }

  function handleCPFValidation(value) {
    // const isValid = validarCPF(value);
    // if (isValid) {
    //   setErrors((errors) => {
    //     return { ...errors, cpf: null };
    //   });
    //   return;
    // }

    // setErrors((errors) => {
    //   return { ...errors, cpf: 'CPF inválido' };
    // });
  }

  function handleEmailValidation(value) {
    const isValid = validarEmail(value);
    if (isValid) {
      setErrors((errors) => {
        return { ...errors, email: null };
      });
      return;
    }

    setErrors((errors) => {
      return { ...errors, email: 'Email inválido' };
    });
  }

  function handlePasswordValidation(confirmPassword) {
    if (password !== confirmPassword) {
      setErrors((errors) => {
        return {
          ...errors,
          confirmPassword: 'Senhas não conferem',
        };
      });
      return;
    }

    setErrors((errors) => {
      return { ...errors, confirmPassword: null };
    });
  }

  async function handleSubmit(formEvent) {
    formEvent.preventDefault();
    try {
      setLoading(true);
      const form = new FormData(formEvent.target);
      const data = formFields.reduce((obj, field) => {
        obj[field] = form.get(field);
        return obj;
      }, {});

      await authApi.signUp(data);

      const session = await authApi.createSession({
        email: data.email,
        password: data.password,
      });
      saveSession(session);

      formEvent.target.reset();
      setNotificationMessage(intl('buyerCreatedSuccessfully'));
      const lastBatchLink = getLastBatch();
      if (lastBatchLink) {
        history.replace(lastBatchLink);
      } else {
        history.push(`${process.env.PUBLIC_URL}/`);
      }
    } catch (err) {
      const error = {
        message: err.response.data.error || 'não foi possível cadastrar',
        type: 'error'
      }
      setNotificationMessage(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Notification
        message={notificationMessage?.message}
        isOpen={!!notificationMessage?.message}
        onClose={resetNotificationMessage}
        type={notificationMessage?.type}
      />
      <div className="login-section pt-120 pb-120">
        <img
          alt="imges"
          src="assets/images/bg/section-bg.png"
          className="img-fluid section-bg-top"
        />
        <img
          alt="imges"
          src="assets/images/bg/section-bg.png"
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title">
                  <h3>{intl('singUpTitle')}</h3>
                  <p>
                    {intl('alreadyMember')}{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/login`}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}
                    >
                      {intl('logIn')}
                    </Link>
                  </p>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="w-100 d-flex flex-column gap-3"
                >
                  <FormInput
                    name="name"
                    label={intl('name')}
                    placeholder="ex: João Enzo"
                    error={errors?.name}
                    required
                  />
                  <FormInput
                    name="email"
                    label={intl('email')}
                    placeholder="ex: joaoenzo@gmail.com"
                    error={errors?.email}
                    required
                    onChange={handleEmailValidation}
                  />

                  <div className="d-flex flex-wrap flex-lg-nowrap gap-3">
                    <FormInput
                      name="cpf"
                      label={intl('cpf')}
                      placeholder="111.111.111-11"
                      error={errors?.cpf}
                      onChange={handleCPFValidation}
                    />
                    <FormInput
                      name="rg"
                      label={'RG'}
                      error={errors?.rg}
                    />
                  </div>

                  <PasswordInput
                    name="password"
                    label={intl('password')}
                    handlePassword={setPassword}
                    
                  />
                  <PasswordInput
                    name="confirmPassword"
                    label={intl('confirmPassword')}
                    error={errors?.confirmPassword}
                    handlePassword={handlePasswordValidation}
                  />

                  <InputMaskForm
                    name="phone"
                    label={intl('phone')}
                    placeholder="ex: (00) 9 1567-1498"
                    required
                  />

                  <DatePickerInput
                    name="birthDate"
                    type="date"
                    label={intl('birthdate')}
                    error={errors?.birthDate}
                  />

                  <FormInput
                    name="country"
                    label={intl('country')}
                    error={errors?.country}
                    type="address"
                    placeholder="ex: Brasil"
                    defaultValue="Brasil"
                  />

                  <FormInput
                    name="state"
                    label={intl('state')}
                    error={errors?.state}
                    type="address"
                    placeholder="ex: MG"
                  />

                  <FormInput
                    name="city"
                    label={intl('city')}
                    error={errors?.city}
                    type="address"
                    placeholder="ex: Governador valadares"
                  />

                  <FormInput
                    name="postalCode"
                    label={'CEP'}
                    error={errors?.postalCode}
                    type="address"
                    placeholder="ex: 12345-678"
                  />

                  <FormInput
                    name="streetAddress"
                    label={intl('address')}
                    error={errors?.streetAddress}
                    type="address"
                    placeholder={`ex: ${intl('streetExample')}`}
                  />

                  <button
                    type="submit"
                    className="account-btn"
                    key={hasErrors}
                    disabled={loading}
                  >
                    {intl('singUpAction')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { BuyerRegistration };
