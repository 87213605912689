import React, { useCallback, useState, useEffect } from 'react';
import { intl } from 'lib/intl';
import { bidsApi } from 'api';
import { toPrice } from 'utils/converters';
import { Pagination } from 'components/pagination';
import { useHistory } from 'react-router-dom';
import { SkeletonLoading } from 'components/common/SkeletonLoading';
import VisibilityIcon from '@mui/icons-material/Visibility';

const PAGE_SIZE = 10;

const FILTERS = {
  page: 1,
  pageSize: PAGE_SIZE,
};

function ContentOfOrder() {
  const history = useHistory();

  const [lastBids, setLastBids] = useState({});
  const [filters, setFilters] = useState(FILTERS);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (filters) => {
    try {
      setLoading(true);
      const bids = await bidsApi.listLastBids(filters);
      console.info(bids);
      setLastBids(bids);
    } catch (err) {
      console.error('Error fetching buyer data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => getData(filters), [getData, filters]);

  const handlePage = (_, page) => {
    setFilters((old) => ({ ...old, page, pageSize: PAGE_SIZE }));
  };

  const changePerPage = (e) => {
    setFilters((old) => ({ ...old, pageSize: e.target?.value || PAGE_SIZE }));
  };

  const goToBatch = (id) => {
    const linkTo = `${process.env.PUBLIC_URL}/batch-details/${id}`;
    history.push(linkTo);
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="v-pills-order"
        role="tabpanel"
        aria-labelledby="v-pills-order-tab"
      >
        <div className="table-title-area">
          <h3>{intl('lastBids')}</h3>
        </div>
        <div className="table-wrapper">
          {loading ? (
            <SkeletonLoading.TableLoading />
          ) : (
            <table className="eg-table order-table table mb-0">
              <thead>
                <tr>
                  <th>{intl('image')}</th>
                  <th>{intl('batch')}</th>
                  <th>{intl('producer')}</th>
                  <th>{intl('variety')}</th>
                  <th>{intl('value')}</th>
                  <th>{intl('city')}</th>
                  <th>{intl('action')}</th>
                </tr>
              </thead>
              <tbody>
                {lastBids?.content?.length > 0 &&
                  lastBids?.content?.map((item) => {
                    const bgImage =
                      process.env.PUBLIC_URL + '/images/bg/empty-image.png';

                    const firstPhotoUrl = item.photos?.[0]?.imageUrl || bgImage;

                    return (
                      <tr key={item.id}>
                        <td className="image-cell" data-label="Imagem">
                          <img
                            alt={item.name}
                            src={firstPhotoUrl}
                            className="img-fluid"
                          />
                        </td>
                        <td data-label={intl('batch')}>{item.name}</td>
                        <td data-label={intl('producer')}>{item?.producer}</td>
                        <td data-label={intl('variety')}>{item.variety}</td>
                        <td data-label={intl('value')}>
                          {toPrice(item?.value)}
                        </td>
                        <td data-label={intl('city')} className="text-green">
                          {item?.city} / {item?.state}
                        </td>
                        <td data-label="Ação">
                          <button
                            onClick={() => goToBatch(item?.batchid)}
                            className="eg-btn action-btn green"
                          >
                            <VisibilityIcon style={{ color: '#fff' }} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        <Pagination
          page={filters.page}
          rowsPerPage={filters.pageSize}
          onChange={handlePage}
          changePerPage={changePerPage}
          total={lastBids?.pagination?.total}
        />
      </div>
    </>
  );
}

export default ContentOfOrder;
