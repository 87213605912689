import React, { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import TopbarHeader from './TopbarHeader';
import { intl } from 'lib/intl';
import { useAuctionContext } from 'pages/auction-details';
import { useAuth } from 'hooks/use-auth';
import { LanguageSelector } from 'components/language-selector';
import { UserTypes } from 'utils/UserTypes';

function Header() {
  const { user } = useAuth();

  const [search, setSearch] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector('.header-area');
    if (header) {
      const scrollTop = window.scrollY;
      if (scrollTop >= 20) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    } else {
      console.warn('Elemento .header-area não encontrado.');
    }
  };
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const searchFullScreen = () => {
    if (search === false || search === 0) {
      setSearch(1);
    } else {
      setSearch(false);
    }
  };

  const initialState = { activeMenu: '' };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case 'homeOne':
        return { activeMenu: 'homeOne' };
      case 'pages':
        return { activeMenu: 'pages' };
      case 'news':
        return { activeMenu: 'news' };
      case 'brows':
        return { activeMenu: 'brows' };
      case 'itwork':
        return { activeMenu: 'itwork' };
      case 'about':
        return { activeMenu: 'about' };
      case 'contact':
        return { activeMenu: 'contact' };
      default:
        return { activeMenu: '' };
    }
  }

  const handleMenu = () => {
    handleSidbarMenu();
    scrollTop();
  };

  return (
    <>
      <TopbarHeader />
      <div className={search === 1 ? 'mobile-search slide' : 'mobile-search'}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <label>What are you lookking for?</label>
              <input
                type="text"
                placeholder="Search Products, Category, Brand"
              />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center">
              <div className="search-cross-btn " onClick={searchFullScreen}>
                <i class="bi bi-search me-4"></i>
                <i className="bi bi-x" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header-area style-1">
        <div className="header-logo">
          <Link to={`/`} onClick={handleMenu}>
            <img
              alt="logo"
              src={process.env.PUBLIC_URL + '/images/bg/logo-selecta-cafe.png'}
            />
          </Link>
        </div>
        <div className={sidebar === 1 ? 'main-menu show-menu' : 'main-menu'}>
          <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
            <div className="mobile-logo-wrap ">
              <Link to={'/'}>
                <img
                  alt="logo2"
                  src={
                    process.env.PUBLIC_URL +
                    '/images/bg/logo-selecta-cafe-sm.png'
                  }
                />
              </Link>
            </div>
            <div className="menu-close-btn" onClick={handleSidbarMenu}>
              <i className="bi bi-x-lg" />
            </div>
          </div>
          <div className="d-lg-none d-flex topbar-right">
            <LanguageSelector />
          </div>
          <ul className="menu-list">
            <li>
              <Link
                onClick={handleSidbarMenu}
                to={'/'}
                className={`${
                  state.activeMenu === 'homeOne' ? 'active' : ''
                } ${'drop-down'}`}
              >
                {intl('home')}
              </Link>
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/event-page`}
                onClick={handleMenu}
                className={`${state.activeMenu === 'brows' ? 'active' : ''} `}
              >
                {intl('events')}
              </Link>
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/my-account`}
                onClick={handleMenu}
                className={`${state.activeMenu === 'contact' ? 'active' : ''} `}
              >
                {intl('myAccount')}
              </Link>
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/my-account`}
                onClick={handleMenu}
                className={`${state.activeMenu === 'contact' ? 'active' : ''} `}
              >
                {intl('myCoffees')}
              </Link>
            </li>
            {user?.linkType === UserTypes.ADMIN ? (
              <li>
                <Link
                  to={`${process.env.PUBLIC_URL}/admin-dashboard`}
                  onClick={handleMenu}
                  className={`${
                    state.activeMenu === 'contact' ? 'active' : ''
                  } `}
                >
                  Admin Dashboard
                </Link>
              </li>
            ) : (
              <></>
            )}
          </ul>
          {/* mobile-search-area */}
          {/* <div className="d-lg-none d-block">
            <form className="mobile-menu-form">
              <div className="input-with-btn d-flex flex-column">
                <input type="text" placeholder="Search here..." />
                <button type="submit" className="eg-btn btn--primary btn--sm">
                  Search
                </button>
              </div>
            </form>
          </div> */}
        </div>
        <div className="nav-right d-flex align-items-center">
          <div
            className="mobile-menu-btn d-lg-none d-block"
            onClick={handleSidbarMenu}
          >
            <i className="bx bx-menu" />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
