import React from 'react';
import './loading.css';
import { intl } from 'lib/intl';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-box">
        <span className="loading-text">{intl('loading')}</span>
        <CircularProgress style={{ color: '#50B19B' }} size={20} />
      </div>
    </div>
  );
};

export { Loading };
