import { apiBase } from '../index';

export const adminApi = {
  listAdminVotes: async (params) =>
    await apiBase
      .get('/admin/batches-votes', { params })
      .then((res) => res.data),

  listAdminOffers: async (params) =>
    await apiBase.get('/admin/bids', { params }).then((res) => res.data),
};
