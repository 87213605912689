import React from 'react';

import HeroBanner from './components/HeroBanner';
import { Events } from './components/Events';
import TestimonialHome1 from './components/TestimonialHome1';

const Home = () => {
  return (
    <>
      <HeroBanner />
      <Events />
      <TestimonialHome1 />
    </>
  );
};

export { Home };
