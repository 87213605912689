import React from 'react';
import { LiveAuctionWrap } from './components/LiveAuctionWrap';

const LiveAuction = () => {
  return (
    <div className="bg-light w-100 h-100 d-flex flex-column gap-4">
      <LiveAuctionWrap />
    </div>
  );
};

export { LiveAuction };
