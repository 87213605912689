import React, { useState, useEffect } from 'react';
import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import { Home } from 'pages/home';
import Preloader from './Preloader';

function MainLayout() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader styles="preloader" />
      ) : (
        <>
          <Header />
          <Home />
          <Footer />
        </>
      )}
    </>
  );
}

export default MainLayout;
