import { apiBase } from '../index';

export const bidsApi = {
  create: async (data) =>
    await apiBase.post('/bids', data).then((res) => res.data),
  list: async () => await apiBase.get('/bids').then((res) => res.data),
  listLastBids: async (params) =>
    await apiBase.get('/bids/last-bids', { params }).then((res) => res.data),
  showLastBid: async (params) =>
    await apiBase.get('/bids/last-bid', { params }).then((res) => res.data),
  get: async (id) => await apiBase.get(`/bids/${id}`).then((res) => res.data),
  update: async (id, newData) =>
    await apiBase.put(`/bids/${id}`, newData).then((res) => res.data),
  remove: async (id) =>
    await apiBase.delete(`/bids/${id}`).then((res) => res.data),
};
