import { TextField } from '../components/text-field';

const FormInput = ({
  label,
  value,
  onChange,
  placeholder,
  disabled = false,
  error = null,
  ...props
}) => {
  const handleOnChange = (event) => {
    const newValue = event.target.value;
    if (onChange) onChange(newValue);
  };

  return (
    <TextField
      fullWidth
      type="text"
      size="medium"
      value={value}
      label={label}
      variant="outlined"
      autoComplete="off"
      disabled={disabled}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={props?.type === 'password' ? '123' : undefined}
      onChange={handleOnChange}
      placeholder={placeholder || label}
      error={error}
      style={{
        width: '100%',
        backgroundColor: 'white',
        opacity: disabled ? '0.4' : '1',
      }}
      {...props}
    />
  );
};

export { FormInput };
