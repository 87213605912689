import React from 'react';
import { Dialog } from '@mui/material';
import { ModalHeader, ModalFooter } from './components';

import './styles.css';

const paperProps = {
  style: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    width: '480px',
  },
};

const Modal = ({
  open,
  title,
  onClose,
  onSubmit,
  children,
  onClick,
  disabled,
  textButton,
  isLoading = false,
  clickOutside = true,
  typeButton = 'button',
}) => {
  const handleOnSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onSubmit) onSubmit();
  };

  const handleClose = () => {
    if (clickOutside) onClose();
  };

  return (
    <div className="component-modal-container">
      <Dialog open={open} PaperProps={paperProps} onClose={handleClose}>
        <ModalHeader title={title} onClose={onClose} />
        <form className="component-modal-form" onSubmit={handleOnSubmit}>
          <div className="component-modal-content">{children}</div>
          <ModalFooter
            onClick={onClick}
            onClose={onClose}
            disabled={disabled}
            isLoading={isLoading}
            textButton={textButton}
            typeButton={typeButton}
          />
        </form>
      </Dialog>
    </div>
  );
};

export { Modal };
