import React from 'react';
import BlogWrap from './components/BlogWrap';
import Breadcrumb from 'components/common/Breadcrumb';
import AboutUsCounter from 'components/common/AboutUsCounter';

const Blog = () => {
  return (
    <>
      <Breadcrumb pageTitle="Blog" pageName="Our Blog" />
      <BlogWrap />
      <AboutUsCounter />
    </>
  );
};

export { Blog };
